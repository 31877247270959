import type { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';
import getConfig from 'next/config';

import { Head } from '@/components';
import { LandingPageRenderer, PermutationPageRenderer } from '@/components/SectionListRenderer';

import type { IEventList, ILandingPage, ITag, IThumbnailSection } from '@/interfaces';

import { getAllEventThumbnails, getAllThumbnails, getLandingPage, getTag } from '@/fetchers';
import { capitalize, checkIsPreviewModeRequest, getArticleTypeNameFromSlug } from '@/utils';
import createServerSidePropsResult, { type IThunk } from '@/utils/createServerSidePropsResult.utils';

import {
  ArticleTypeName,
  CONNECTING_TO_CONTENTFUL,
  CONNECTING_TO_REDIS,
  ContentTypeName,
  ThunkDebugName,
  serverSharedData,
} from '@/constants';
import { utils_getRightSectionList } from '@/fetchers/utils';

const { serverRuntimeConfig } = getConfig();

const ArticleSummary: NextPage<InferGetServerSidePropsType<typeof getServerSideProps>> = ({
  landingPageData,
  articlePermutationContent,
  webVersion,
}) => {
  console.info(webVersion);

  if (landingPageData) {
    return (
      <>
        {landingPageData.seo && <Head {...landingPageData.seo} />}
        <LandingPageRenderer
          key={landingPageData.sys.id}
          sectionList={landingPageData.sectionList}
          content={landingPageData.content}
          breadcrumbsTheme={landingPageData.breadcrumbsTheme}
          title={landingPageData.title}
          onlyLogo={landingPageData.onlyLogo}
        />
      </>
    );
  }

  return (
    <>
      {articlePermutationContent?.seo && <Head {...articlePermutationContent.seo} />}
      <PermutationPageRenderer
        sectionList={articlePermutationContent?.sectionList || []}
        title={articlePermutationContent?.title || ''}
        rightSectionList={articlePermutationContent?.rightSectionList || []}
        breadcrumbsTheme={articlePermutationContent?.breadcrumbsTheme}
      />
    </>
  );
};
interface IArticleSummaryPageServerSideProps {
  articlePermutationContent: ITag | null;
  landingPageData: ILandingPage | null;
  webVersion: string;
}

interface IArticleSummaryParams extends NodeJS.Dict<string> {
  articleTypeSlug: string;
}

type Thunk = IThunk<IArticleSummaryPageServerSideProps, IArticleSummaryParams>;

const getRedisUtils: Thunk = async ({ nextJsContext, customContext }) => {
  if (!customContext.serverRuntimeConfig.useDev) {
    const redisUtils = await import('@/utils/redis.utils');

    customContext.getServerPropsCached = redisUtils.getServerPropsCached;
    customContext.cacheServerProps = redisUtils.cacheServerProps;
    customContext.setUrlById = redisUtils.setUrlById;
  }

  nextJsContext.res.setHeader('Cache-Control', 'public, s-maxage=43200, stale-while-revalidate=60');
};

getRedisUtils.debugName = ThunkDebugName.GetRedisUtils;

const getServerPropsCached: Thunk = async ({ nextJsContext, customContext }) => {
  const { resolvedUrl } = nextJsContext;
  customContext.pageURL = resolvedUrl.split('?')[0];

  const serverPropsCached: IArticleSummaryPageServerSideProps = JSON.parse(
    (await customContext.getServerPropsCached(customContext.pageURL)) || 'null',
  );

  if (serverPropsCached) {
    return {
      props: {
        ...serverPropsCached,
        webVersion: CONNECTING_TO_REDIS,
      },
    };
  }
};

getServerPropsCached.debugName = ThunkDebugName.GetServerPropsCached;

const getLandingPageData: Thunk = async ({ nextJsContext, customContext }) => {
  const { query, params } = nextJsContext;
  const { serverSideProps, setUrlById, cacheServerProps, pageURL } = customContext;
  const isPreviewMode = checkIsPreviewModeRequest(query);
  customContext.isPreviewMode = isPreviewMode;

  const [allEvents, allArticles] = await Promise.all([
    getAllEventThumbnails({ isPreviewMode, slug: pageURL }),
    getAllThumbnails({ isPreviewMode, slug: pageURL }),
  ]);

  customContext.allEvents = allEvents;
  customContext.allArticles = allArticles;

  const { articleTypeSlug = '' } = params || {};
  customContext.articleTypeName = getArticleTypeNameFromSlug(articleTypeSlug);
  const articleTypeName = customContext.articleTypeName;

  if (!articleTypeName || articleTypeName === ArticleTypeName.markets) {
    serverSideProps.landingPageData = await getLandingPage({
      isPreviewMode,
      slug: articleTypeSlug,
      allEventsList: allEvents,
      allArticlesList: allArticles,
      accessToken: serverSharedData.authenticatorAccessToken,
    });

    if (serverSideProps.landingPageData) {
      if (!isPreviewMode) {
        setUrlById(serverSideProps.landingPageData.sys.id, pageURL);
        cacheServerProps(pageURL, serverSideProps);
      }

      return {
        props: {
          ...serverSideProps,
        },
      };
    }

    return {
      props: {
        ...serverSideProps,
      },
      notFound: true,
    };
  }
};

getLandingPageData.debugName = 'GET_LANDING_PAGE_DATA';

const getArticlePermutationData: Thunk = async ({ customContext }) => {
  const {
    serverSideProps,
    isPreviewMode,
    articleTypeName,
    allArticles,
    allEvents,
    pageURL,
    setUrlById,
    cacheServerProps,
  } = customContext;

  const articlePermutationContent = await getTag({
    isPreviewMode,
    articleTypeName,
    allArticlesList: allArticles,
    allEventsList: allEvents,
  });

  if (articlePermutationContent?.hide) {
    return {
      notFound: true,
    };
  }

  serverSideProps.articlePermutationContent = articlePermutationContent;

  if (serverSideProps.articlePermutationContent) {
    serverSideProps.articlePermutationContent.rightSectionList = utils_getRightSectionList({
      articles: allArticles,
      events: allEvents,
      data: serverSideProps.articlePermutationContent.rightSectionList,
    });
  }

  const foundEventListSection: IEventList = articlePermutationContent?.sectionList?.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.EventList,
  );

  const foundThumbnailSection: IThumbnailSection = articlePermutationContent?.sectionList?.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.ThumbnailSection,
  );

  if (foundEventListSection && !foundEventListSection.featured && foundThumbnailSection) {
    foundThumbnailSection.latestData = foundThumbnailSection.latestData.filter(
      (thumbnailItem) =>
        !foundEventListSection.eventList.find((featuredEventItem) => featuredEventItem.sys.id === thumbnailItem.sys.id),
    );

    foundThumbnailSection.foundEventListSection = foundEventListSection;
  }

  if (foundThumbnailSection) {
    const totalThumbnails = foundThumbnailSection.latestData.length;
    const totalPages = Math.ceil(totalThumbnails / 10);

    foundThumbnailSection.pageInfo = {
      itemsPerPage: 10,
      totalPages,
    };

    foundThumbnailSection.latestData = foundThumbnailSection.latestData.slice(0, 10);
  }

  if (!isPreviewMode && serverSideProps.articlePermutationContent) {
    setUrlById(serverSideProps.articlePermutationContent.sys.id, pageURL);
    cacheServerProps(pageURL, serverSideProps);
  }

  return {
    props: {
      ...serverSideProps,
    },
  };
};

getArticlePermutationData.debugName = 'GET_ARTICLE_PERMUTATION_DATA';

export const getServerSideProps: GetServerSideProps<IArticleSummaryPageServerSideProps, IArticleSummaryParams> = async (
  context,
) => {
  return createServerSidePropsResult<IArticleSummaryPageServerSideProps, IArticleSummaryParams>({
    context,
    customContext: {
      serverSideProps: {
        landingPageData: null,
        articlePermutationContent: null,
        webVersion: CONNECTING_TO_CONTENTFUL,
      },
      serverRuntimeConfig,
      getServerPropsCached: () => null,
      cacheServerProps: () => null,
      setUrlById: () => null,
    },
    thunks: [getRedisUtils, getServerPropsCached, getLandingPageData, getArticlePermutationData],
  });
};

export default ArticleSummary;
